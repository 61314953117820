.App {
    text-align: center;
}

body {
    background-color: #f5f5f5;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.row {
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
}

.row-small .row-text {
    margin-top: 0;
}

.row-container.first {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.row-container {
    padding: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-radius: 1rem;
    background-color: #fff;
    margin-bottom: 2.5rem;
    transition: all 2s linear;
    display: block;
}

.row-text {
    display: inline-block;
    margin: 0.5rem;
}

.row-text .small {
    font-size: 0.7rem;
}

.list {
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    margin-right: -15px;
    margin-left: -15px;
}

.list-row {
    padding-left: 15px;
    padding-right: 15px;
}

.list p {
    text-align: left;
    margin: 0.5rem;
    margin-top: 0;
}

.container {
    display: flex;

    align-items: center;
    margin-top: 5rem;
    flex-direction: column;
}

.info-block {
    max-width: 30rem;
    width: 100%;
    margin-top: 2.5rem;
}

.orange {
    color: #ff6200;
}

.bold {
    font-weight: bold;
}

.gray {
    color: gray;
}

a {
    text-decoration: none;
}

.collapse-button {
    width: 100%;
    background-color: white;
    border: 1px solid white;

    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    outline: none;
}

.collapse-button:hover {
    background-color: #ededed;
    border: 1px solid #ededed;
}

.collapse-button:focus {
    outline: none;
}

.hidden {
    display: none;
}

.img-row > a img {
    width: 140px;
    height: 140px;
    object-fit: cover;
    border-radius: 5px;
    margin: 10px;
}

.divider {
    height: 2;
    background-color: gray;
    width: calc(100% - 25px);
}

.row-button {
    margin-left: 15px;
    margin-right: 15px;
}

.btn-orange {
    background-color: #ff6200;
    color: white;
}

.modal-body {
    text-align: left;
}

.modal-body .row input[type="range"] {
    width: 50%;
}

.modal-body .row p {
    margin: 0;
    margin-left: 15px;
}

.modal-body .row {
    flex: 1;
    justify-content: flex-start;
    align-content: center;
    margin-left: 0px;
}

.progress-visualizer p {
    margin: 0;
}

meter {
    width: 100%;
    height: 31px;
}

meter::-webkit-meter-bar {
    border: 1px solid #ff6200;
    border-radius: 3px;
}

meter::-webkit-meter-optimum-value {
    background-color: #ff6200;
}

.review-meter {
    width: 100%;
    height: 31px;
    margin-left: 10px;
    margin-right: 10px;
}

.row-small p {
    text-align: left;
}
